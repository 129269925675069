"use strict";

const likely = require('ilyabirman-likely');
window.throttle = require('vendor/throttle');
window.I18n = require('I18n');
window.Cookies = require('js-cookie');
const scrollSaver = require('scrollSaver');
if (!window.fetch) require('whatwg-fetch');
require('es6-object-assign').polyfill();
require('array-includes');
require('array.prototype.find').shim();
require('lib/animationsObject')($);
require('lib/dataCopy')($);
require('lib/equalize')();
require('lib/textHide');
require('vendor/fotorama');
require('vendor/object.values-polyfill');
require('vendor/padStart-polyfill');
require('inobounce');
const lozad = require('lozad');
const MobileTrueHeight = require('lib/mobileTrueHeight');
new MobileTrueHeight();
$(function () {
  const $document = $(document);
  const fontsReady = require('fontsReady');
  const Webpage = require('app/webpage');
  const CoworkafeLive = require('app/coworkafeLive');
  const ImageLoader = require('app/imageLoader');
  const RetinaImageLoader = require('app/retinaImageLoader');
  const DynamicPlaceholders = require('app/dynamicPlaceholders');
  const Header = require('./header');
  const Menu = require('./menu');
  const PageMenu = require('./pageMenu');
  const DoubleHover = require('./doubleHover');
  const EditLink = require('app/editLink');
  const SubscriptionStats = require('app/subscriptionStats');
  const SchoolEnrollSwitcher = require('app/schoolEnrollSwitcher');
  const LocalState = require('lib/localState');
  const DnD = require('app/dnd');
  const DirtyForms = require('app/dirtyForms');
  const Banners = require('app/banners');
  const Viewport = require('lib/viewport');
  const DocumentProgress = require('app/documentProgress');
  const setDynamicUtmLinkDates = require('lib/setDynamicUtmLinkDates');
  const reachGoal = require('lib/reachGoal');
  const iframeResize = require('iframeResizer');
  iframeResize({}, '.iframeAutoResizable');
  if (document.body.className.includes('is__embeddable')) {
    const embeddableEvents = require('lib/embeddableEvents');
    embeddableEvents.bind();
  }
  if ($('.lozad').length) lozad().observe();
  window.application.state = new LocalState();
  window.application.templates = require('./templates');
  window.application.errors = require('./errors');
  window.application.can = () => false;
  window.application.isProduction = document.body.classList.contains('is__production');
  window.application.gridSpacing = 9;
  Object.defineProperty(window.application, 'currentScrollPosition', require('lib/currentScrollPosition'));
  const meta = document.head.querySelector('[name~=pageId][content]');
  window.application.pageId = (meta || {}).content;
  window.application.isOnMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  window.application.supports = {
    halfPixel: require('supports/halfPixel')(),
    retina: require('supports/retina')()
  };
  for (var property in window.application.supports) {
    if (window.application.supports[property]) {
      $('body').addClass('has__' + property);
    } else {
      $('body').addClass('no__' + property);
    }
  }
  const BREAKPOINTS = [960, 1400];
  require('modules/modules/_base/__.js')
require('modules/modules/_base/base.js')
require('modules/modules/_module/animatedModule.js')
require('modules/modules/_module/animatedSequenceModule.js')
require('modules/modules/_module/animatedSlaveModule.js')
require('modules/modules/_module/animatedStylesModule.js')
require('modules/modules/_module/animationsPlayer.js')
require('modules/modules/_module/fader.js')
require('modules/modules/_module/fitToProportionModule.js')
require('modules/modules/_module/foldableModule.js')
require('modules/modules/_module/marginalModule.js')
require('modules/modules/_module/zoomedModule.js')
require('modules/modules/cols/autoCols.js')
require('modules/modules/example.legacy/example.js')
require('modules/modules/explanation/explanationControl.js')
require('modules/modules/image/bleedImage.js')
require('modules/modules/image/evenwidthImages.js')
require('modules/modules/image/fitToContentModule.js')
require('modules/modules/image/panoramicImage.js')
require('modules/modules/image/playableSvg.js')
require('modules/modules/screenshot/screenshot.js')
require('modules/modules/stickyTable/stickyTable.js')
require('modules/modules/switcher/fitToContentSwitcher.js')
require('modules/modules/test/answer.js')
require('modules/modules/test/locale.js')
require('modules/modules/test/question.js')
require('modules/modules/test/standaloneQuestion.js')
require('modules/modules/test/test.js')
require('modules/modules/video/video.js')
require('modules/modules/video/videoPlay.js')
require('modules/modules/video/videoPlayInView.js');
  const importAll = r => r.keys().forEach(r);
  importAll(require.context('../../modules/', true, /\.js$/));
  const initApp = () => fontsReady(function () {
    window.application.webpage = new Webpage($('.js__webpage'));
    if ($('.fotorama').length) {
      $('.fotorama').on('fotorama:load', function () {
        $(document).trigger('appLoadImagesIn', $(this));
      }).fotorama();
      $('.fotorama.is__looped').off('click touchstart touchend touchmove', '.fotorama__stage');
      $('.fotorama.is__looped').on('click touchend', '.fotorama__stage', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).closest('.fotorama').data('fotorama').show('>');
      });
    }
    new ImageLoader();
    new RetinaImageLoader();
    new EditLink();
    new DnD();
    new DirtyForms();
    new Banners();
    new Viewport();
    new DocumentProgress();
    setDynamicUtmLinkDates();
    $('[data-toggle-class]').each(function () {
      const $el = $(this);
      const togglableClass = $el.attr('data-toggle-class');
      $el.on('click', '.js__toggle', () => $el.toggleClass(togglableClass));
    });
    $('.js__schoolEnrollSwitcher').each(function () {
      new SchoolEnrollSwitcher($(this));
    });
    const dynamicPlaceholders = new DynamicPlaceholders($('body'), {
      rotations: {
        soviet: window.application.alternatingSoviets,
        course: window.application.alternatingCourses,
        category: window.application.promotedCategories
      },
      templates: window.application.templates
    });
    const getCurrentLayout = () => {
      for (let i = 0; i < BREAKPOINTS.length; i++) {
        if (window.innerWidth < BREAKPOINTS[i]) {
          return i;
        }
      }
      return BREAKPOINTS.length;
    };
    let currentLayout = getCurrentLayout();
    $document.on('appResize', () => {
      const newCurrentLayout = getCurrentLayout();
      if (currentLayout !== newCurrentLayout) {
        currentLayout = newCurrentLayout;
        $document.trigger('appLayoutChange');
      }
    });
    dynamicPlaceholders.fill();
    $document.on('appLayoutChange', dynamicPlaceholders.fill.bind(dynamicPlaceholders));
    $('.js__header').each(function () {
      new Header($(this));
    });
    $('.js__coworkafeLive').each(function () {
      new CoworkafeLive($(this));
    });
    $('.js__menu').each(function () {
      new Menu($(this));
    });
    $('.js__pageMenu').each(function () {
      new PageMenu($(this));
    });
    $('[data-subscription-stats-for]').each(function () {
      new SubscriptionStats($(this));
    });
    $(document).on('click submit', '[data-goal]', function () {
      reachGoal($(this).attr('data-goal'));
    });
    $document.trigger('appSetUser', window.application.user);
    const disableINoBounce = () => {
      try {
        iNoBounce.disable();
      } catch (e) {
        setTimeout(disableINoBounce, 50);
      }
    };
    disableINoBounce();
    let $debugger = $('<div>');
    let isDebugMode = false;
    let isGridBuilt = false;
    const buildGrid = function () {
      $('body').append(`
        <div class="debugger">
          <div class="debugger-cols">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
      `);
    };
    $(document).on('keydown', e => {
      if (e.keyCode === 71 && e.ctrlKey) {
        if (!isGridBuilt) {
          buildGrid();
          isGridBuilt = true;
          $debugger = $('.debugger');
        }
        $debugger.toggleClass('is__active').css({
          height: document.body.clientHeight
        });
        isDebugMode = !isDebugMode;
      }
      if (e.keyCode === 66 && e.ctrlKey) {
        const $banners = $('.banner-debug');
        $banners.toggleClass('is__active');
      }
      if (isDebugMode && (e.keyCode === 38 || e.keyCode === 40)) {
        e.preventDefault();
        const offset = parseInt($debugger.css('top'), 10);
        const newOffset = e.keyCode === 38 ? offset - 1 : offset + 1;
        $debugger.css('top', newOffset + 'px');
      }
    });
    $(document).on('appResize', () => $debugger.css({
      height: document.body.clientHeight
    }));
    $('.preload').each(function () {
      const $loadbox = $(this);
      const images = $(this).find('.image').map((_, el) => {
        const img = new Image();
        img.src = $(el).data('src');
        return img;
      });
      imagesLoaded(images, function () {
        $loadbox.addClass('is__loaded');
      });
    });
    const replaceSVG = () => {
      $('.js__inlineSVG').each(function () {
        const $img = $(this);
        const imgID = $img.attr('id');
        const imgClass = $img.attr('class');
        const imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
          let $svg = $(data).find('svg');
          if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
          }
          if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
          }
          $svg = $svg.removeAttr('xmlns:a');
          $img.replaceWith($svg);
        }, 'xml');
      });
    };
    $document.on('appLayoutChange', replaceSVG);
    replaceSVG();
    $document.on('imagesLoaded', () => {
      $('body').addClass('imagesLoaded');
    });
    scrollSaver.initiate();
    likely.initiate();
    const shouldDisplayBlackFridayBadge = () => {
      if (/blackfriday/.test(window.location.search)) return true;
      return new Date() < new Date('Fri Nov 24 2023 23:59:59 GMT+0300') && new Date() >= new Date('Fri Nov 24 2023 00:00:00 GMT+0300');
    };
    const appendBadge = function ($badge) {
      let target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Новости';
      if ($('.bookStage.is__head, .bookCard .hero, .bookSplash, .sovietSplash, .compilations-header, .compilationCover, .fullImageTop-wrapper').length) {
        $('body').addClass('has__header-badge');
      }
      $(`.header .menu-item-link:contains("${target}")`).after($badge.clone());
      $('.header').after($badge.clone());
    };
    const appendBlackFridayBadge = () => {
      const $badge = $('<a class="menu-item-badge" href="/news/2023/black-friday/"><b>пт</b> −50% до 23:59 мск</a>');
      appendBadge($badge);
    };
    const shouldDisplaySchoolX2Badge = () => {
      if ($('body').hasClass('is__embeddable')) return false;
      if (/schoolx2/.test(window.location.search)) return true;
      return !/^\/(school|schoolx)\//.test(window.location.pathname);
    };
    const appendSchoolX2Badge = () => {
      const $badge = $(`<a class="menu-item-x2Badge doubleHover" href="/school/">
        <span>Школа</span>
        <svg width="22" height="12" viewBox="0 0 22 12" fill="var(--linkColor)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2709 1.58679L14.5494 2.88652C15.7409 2.08651 18.791 1.44394 18.791 3.4511C18.791 5.38975 15.5238 6.85503 13.7541 7.33558V9.2696C16.4387 8.86488 21.22 6.46068 21.22 3.00011C21.22 1.25495 19.6422 0 17.5661 0C15.5709 0 13.9579 0.964207 13.2709 1.58679ZM21.4014 11.6925H13.7541V9.83188C14.6391 9.78103 20.8852 9.41612 22.0035 9.18091L21.4014 11.6925ZM4.06274 5.63831L0 1.73967L1.51177 0.2L5.57084 4.08012L4.06274 5.63831ZM10.689 0.20179L12.2 1.74064L7.65537 6.1017L12.198 10.4596L10.6871 11.9983L6.11075 7.5861L1.51355 12L0.00198138 10.4605L4.58281 6.06595L4.58286 6.0659L10.689 0.20179Z" fill="inherit"></path>
        </svg>
      </a>`);
      appendBadge($badge, 'Школа');
    };
    if (shouldDisplayBlackFridayBadge()) appendBlackFridayBadge();
    if (shouldDisplaySchoolX2Badge()) appendSchoolX2Badge();
    $('.doubleHover').each(function () {
      new DoubleHover($(this));
    });
  });
  setTimeout(initApp, 1);
});